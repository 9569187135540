<template>
  <van-nav-bar
  :title=userinfo.userName
  left-arrow
  @click-left="onGoBack"
  />
{{userinfo.userName}}
  <van-skeleton title :row="3" />
</template>
<script>

export default {
  components: {

  },
  data(){
    return{
      userinfo:{data:{}}
    }
  },

  mounted(){
    this.get_data()
  },
	methods: {
     
     get_data(){
      //var query=this.$route.query;
      var query=this.$route.params
      this.userinfo=JSON.parse(query.data)
      console.log(this.userinfo.userName)
    },

  }
  
};
</script>

<style lang="less">

</style>
